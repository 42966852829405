import React from "react";
import Spline from "@splinetool/react-spline";
import { Link } from "react-router-dom";
import styles from "./Privacy.module.css"; // Импортируем CSS модуль

function Privacy() {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Link to="/" className={styles["logo-link"]}>
          <h1>MaxSimple</h1>
        </Link>
        <a href="#" className={styles.btn}>
          Войти
        </a>
      </header>

      <main className={styles.content}>
        <div className={styles["spline-scene"]}>
        <Spline scene="https://mxmp.ru/scene.splinecode" />
        </div>
        <div className={styles["text-section-wrapper"]}>
          <div className={styles["text-section"]}>
            <h1>Политика в отношении обработки персональных данных</h1>
            <p>
            (Обработка персональных данных «MXMP» в отношении Вендоров)
            Дата последнего обновления: «11» Февраля 2025г.
            </p>
            <h2>1. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
<p>1.1. Настоящая Политика конфиденциальности (далее – «Политика») определяет порядок обработки и защиты персональных данных, которые передаются (или могут передаваться) в рамках использования платформы MXMP (далее – «Платформа») вендорами (далее – «Вендор» или «Субъект») и/или их уполномоченными представителями. Оператором персональных данных в указанных правоотношениях выступает MXMP (далее – «Оператор» или «MXMP»).</p>
<p>1.2. Политика разработана в соответствии с требованиями законодательства Российской Федерации, в том числе Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» (при условии, что данное законодательство подлежит применению к соответствующим правоотношениям), и иных нормативных правовых актов, регулирующих вопросы обработки персональных данных.</p>
<p>1.3. Использование Платформы, а также принятие оферты или подписание иного соглашения (например, пользовательского соглашения) свидетельствует о согласии Субъекта с условиями настоящей Политики и подтверждает, что Субъект ознакомлен с ней в полном объёме. В случае несогласия с каким-либо пунктом Политики рекомендуется воздержаться от пользования Платформой.</p>
<p>1.4. Политика размещается по адресу: https://mxmp.ru/privacy</p>

<h2>2. ОСНОВНЫЕ ПОНЯТИЯ</h2>
<p>2.1. Оператор (MXMP) – лицо, самостоятельно или совместно с другими лицами определяющее цели и средства обработки персональных данных Субъекта (Вендора) в рамках использования Платформы. Все права на программный код, дизайн, интерфейсы и иные элементы Платформы принадлежат Оператору.</p>
<p>2.2. Вендор (Субъект персональных данных) – юридическое лицо, индивидуальный предприниматель или иной хозяйствующий субъект, а также их уполномоченные представители, которые регистрируются на Платформе и используют её функционал для взаимодействия со своими клиентами, публикации контента, предоставления услуг и т.п.</p>
<p>2.3. Платформа (MXMP) – совокупность программных решений, сервисов и инструментов, предоставляемых Оператором, в том числе на субдоменах вида *.mxmp.ru, для целей создания, размещения и управления контентом, веб-страницами и/или приложениями Вендора.</p>
<p>2.4. Персональные данные – любая информация, относящаяся прямо или косвенно к определённому или определяемому Субъекту персональных данных (Вендору и/или его представителям), которая обрабатывается Оператором при использовании Платформы.</p>
<p>2.5. Политика конфиденциальности «Вендор → Клиент» – отдельный документ, разрабатываемый и предоставляемый самим Вендором для своих клиентов (физических лиц), если эти клиенты передают персональные данные Вендору посредством функционала Платформы.</p>

<h2>3. ЦЕЛИ И ПРАВОВЫЕ ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
<p>3.1. Цели обработки</p>
<p>3.1.1. Обеспечение предоставления функциональных возможностей Платформы, включая регистрацию и авторизацию (в том числе через мессенджеры), а также работу личного кабинета Вендора.</p>
<p>3.1.2. Ведение учётных записей, администрирование деятельности Вендоров на Платформе, включая оказание технической поддержки.</p>
<p>3.1.3. Организация обратной связи, направление уведомлений и информационных сообщений, связанных с работой Платформы.</p>
<p>3.1.4. Защита прав и законных интересов Оператора, включая блокировку контента в случае нарушения законодательства или условий Пользовательского соглашения.</p>
<p>3.1.5. Сбор статистики и проведение аналитики о функционировании Платформы, а также информирование о новых продуктах (при наличии согласия, если это требуется законом).</p>
<p>3.1.6. Соблюдение требований действующего законодательства, включая запросы государственных органов и инстанций.</p>
<p>3.2. Правовые основания</p>
<p>3.2.1. Согласие Субъекта персональных данных на обработку (при регистрации, акцепте пользовательского соглашения и т.п.).</p>
<p>3.2.2. Необходимость обработки для исполнения договора, стороной которого является Субъект (например, договор на использование Платформы).</p>
<p>3.2.3. Иные правовые основания, предусмотренные законодательством Российской Федерации, включая Федеральный закон № 152-ФЗ «О персональных данных», при условии его применимости.</p>

<h2>4. СОСТАВ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
<p>4.1. Данные, предоставляемые при регистрации/авторизации</p>
<p>4.1.1. Имя (или псевдоним), номер телефона, адрес электронной почты, а также иные идентификаторы, включая данные о мессенджерах (Telegram, WhatsApp и др.), если это необходимо для входа в систему.</p>
<p>4.1.2. Дополнительные сведения, касающиеся юридического лица (либо ИП) Вендора (наименование, ИНН, ОГРН, адрес и проч.), если это нужно для оказания услуг или исполнения договора.</p>
<p>4.2. Данные, которые собираются автоматически</p>
<p>4.2.1. IP-адрес, тип и версия браузера (или иного ПО), версия операционной системы, дата и время запросов, статистика использования функционала Платформы, файлы cookie (при их применении).</p>
<p>4.2.2. Прочие технические сведения, необходимые для поддержки стабильной работы Платформы и улучшения пользовательского опыта.</p>
<p>4.3. Иная информация, вводимая Вендором</p>
<p>4.3.1. Сведения о представителях Вендора (Ф.И.О., должность, контактные данные), если требуется для взаимодействия с Оператором.</p>
<p>4.3.2. Реквизиты для выставления счетов и осуществления взаиморасчётов (банковские данные, юридический адрес и т.д.), при условии необходимости для оказания услуг или заключения договора.</p>
<p>4.4. Сведения, связанные с клиентами Вендора</p>
<p>4.4.1. Оператор не является самостоятельным оператором персональных данных конечных клиентов Вендора и не несёт ответственности за соблюдение Вендором законодательства о персональных данных в отношении таких лиц.</p>
<p>4.4.2. При сборе данных клиентов (физических лиц) на страницах, размещённых на Платформе, Вендор обязан предоставить политику «Вендор → Клиент», определяющую цели и порядок обработки таких данных.</p>
<p>4.4.3. В случае использования сервисов Оператора исключительно для хостинга форм или баз данных клиентов, Оператор рассматривается как лицо, обрабатывающее данные по поручению Вендора (ст. 6 152-ФЗ РФ), если такое поручение оформлено.</p>

<h2>5. ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
<p>5.1. Законность, справедливость и прозрачность</p>
<p>5.1.1. Обработка персональных данных осуществляется в соответствии с нормами действующего законодательства. Действия Оператора направлены на обеспечение прозрачности процессов и предоставление информации Субъекту в доступной форме.</p>
<p>5.2. Ограничение цели обработки</p>
<p>5.2.1. Обработка персональных данных допустима исключительно в целях, определённых в разделе 3 настоящей Политики. Любое иное использование допускается только с дополнительным уведомлением и/или согласием Субъекта, если оно требуется законом.</p>
<p>5.3. Адекватность и не избыточность</p>
<p>5.3.1. Собираются только те данные, которые необходимы для достижения целей, указанных в настоящей Политике. Не допускается сбор лишних сведений, не связанных с достижением заявленных целей.</p>
<p>5.4. Точность и актуальность</p>
<p>5.4.1. Оператор предпринимает разумные меры для поддержания персональных данных в точном, актуальном и достоверном виде. Субъект вправе требовать исправления или уточнения своих персональных данных.</p>
<p>5.5. Ограничение срока хранения</p>
<p>5.5.1. Персональные данные хранятся в форме, позволяющей идентифицировать Субъекта, не дольше, чем это необходимо для целей обработки (или в сроки, установленные законодательством). По достижении целей либо по истечении установленных сроков данные подлежат удалению или обезличиванию.</p>
<p>5.6. Информационная безопасность</p>
<p>5.6.1. Оператор использует необходимые организационные и технические меры для защиты данных от неправомерного доступа, утраты, уничтожения, изменения или распространения, а также иных неправомерных действий.</p>
<p>5.7. Соблюдение требований внешних платформ</p>
<p>5.7.1. Обработка персональных данных при необходимости учитывает требования площадок распространения (App Store, Google Play и др.), включая наличие публичной ссылки на Политику и механизмов удаления данных по запросу Субъекта.</p>

<h2>6. РАЗДЕЛЕНИЕ ОТВЕТСТВЕННОСТИ И ПРАВА СТОРОН</h2>
<p>6.1. Ответственность Оператора (MXMP)</p>
<p>6.1.1. Оператор обеспечивает безопасность хранения персональных данных Вендора, применяя необходимые меры защиты в соответствии с законодательством.</p>
<p>6.1.2. Оператор вправе блокировать или удалять контент, если выявлены нарушения законодательства, условий настоящей Политики или иных соглашений (например, пользовательского соглашения).</p>
<p>6.1.3. Оператор не несёт ответственности за контент, размещённый Вендором, и не возмещает убытки, вызванные действиями (бездействиями) Вендора по отношению к третьим лицам.</p>
<p>6.1.4. Оператор оставляет за собой право продолжить обработку персональных данных Субъекта при наличии иных правовых оснований (закон, договор, судебное предписание и т.п.), даже при отзыве согласия.</p>
<p>6.2. Ответственность Вендора</p>
<p>6.2.1. Вендор самостоятельно соблюдает нормы законодательства о персональных данных в отношениях со своими клиентами (физическими лицами), включая разработку и публикацию собственной политики «Вендор → Клиент».</p>
<p>6.2.2. Вендор несёт ответственность за корректность и законность размещаемого контента, а также за взаимодействие с конечными пользователями.</p>
<p>6.2.3. Вендор обязан иметь правовые основания для передачи данных третьих лиц, если такая передача осуществляется с использованием возможностей Платформы (ст. 6 152-ФЗ РФ, при необходимости).</p>
<p>6.2.4. При поступлении к Оператору претензий от третьих лиц по поводу нарушения их прав (в том числе по персональным данным) в результате действий (бездействий) Вендора, Вендор обязуется урегулировать спор своими силами и за свой счёт, освободив Оператора от возможных убытков.</p>
<p>6.3. Ограничения и права MXMP</p>
<p>6.3.1. Оператор имеет право приостановить доступ к Платформе или удалить учётную запись Вендора при выявлении нарушений настоящей Политики или требований законодательства.</p>
<p>6.3.2. Оператор может обрабатывать обезличенные (агрегированные) данные в исследовательских, статистических и аналитических целях.</p>

<h2>7. ЛИЦЕНЗИЯ И ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</h2>
<p>7.1. Принадлежность исключительных прав</p>
<p>7.1.1. Все исключительные права на Платформу, включая программный код, интерфейсы, базы данных, элементы дизайна и знаки обслуживания, принадлежат Оператору (MXMP), если иное не оговорено отдельным письменным соглашением.</p>
<p>7.2. Ограниченная лицензия</p>
<p>7.2.1. Вендору предоставляется неисключительная, ограниченная по цели и сроку лицензия на использование Платформы исключительно в соответствии с её функциональными возможностями и только для внутренних нужд, связанных с хозяйственной деятельностью Вендора.</p>
<p>7.3. Запрет на экспорт</p>
<p>7.3.1. Воспроизведение, копирование, экспорт или размещение веб-страниц (приложений, сгенерированных в Платформе) на сторонних хостингах допускается только с письменного согласия Оператора.</p>
<p>7.3.2. Любые попытки реверс-инжиниринга или иных действий, направленных на получение исходного кода, нарушают права Оператора и могут повлечь ответственность в соответствии с законодательством.</p>

<h2>8. ПОРЯДОК СБОРА, ХРАНЕНИЯ, ПЕРЕДАЧИ И УДАЛЕНИЯ ДАННЫХ</h2>
<p>8.1. Сбор данных</p>
<p>8.1.1. Сбор персональных данных осуществляется посредством форм регистрации, личного кабинета, а также при авторизации через сторонние сервисы (например, мессенджеры).</p>
<p>8.1.2. Дополнительно собирается техническая информация (п. 4.2) в автоматическом режиме при использовании Платформы.</p>
<p>8.2. Хранение данных</p>
<p>8.2.1. Оператор организует хранение персональных данных на собственных или арендуемых серверах, применяя технические и организационные меры защиты от несанкционированного доступа.</p>
<p>8.2.2. Доступ к данным предоставляется уполномоченным сотрудникам (или подрядчикам) Оператора, связанным обязательствами о конфиденциальности.</p>
<p>8.3. Передача данных третьим лицам</p>
<p>8.3.1. Передача персональных данных Вендора третьим лицам допускается в случаях: (a) требования закона или запроса государственных органов; (b) наличия отдельного согласия Вендора; (c) необходимости исполнения договора с Вендором (например, при работе с платёжными сервисами).</p>
<p>8.3.2. Оператор не несёт ответственности за действия третьих лиц, если передача данных осуществлена по инициативе Вендора или на основании заключённого с ним соглашения.</p>
<p>8.4. Сроки обработки и удаление данных</p>
<p>8.4.1. Срок хранения персональных данных определяется целями обработки, установленными в настоящей Политике, а также требованиями закона.</p>
<p>8.4.2. По достижении целей или истечении сроков хранения, данные подлежат удалению или обезличиванию, если иное не продиктовано императивными нормами права.</p>
<p>8.4.3. При необходимости удаления персональных данных или отзыва согласия следует направить запрос на контактный адрес (раздел 10). Оператор вправе продолжать обработку при наличии законных оснований (например, для защиты своих прав).</p>
<p>8.5. Трансграничная передача</p>
<p>8.5.1. В случае размещения серверов или сервисов за пределами Российской Федерации, трансграничная передача персональных данных осуществляется на основании норм действующего законодательства (ст. 12 152-ФЗ РФ), с учётом требований международных стандартов (GDPR и т.д.), если применимо.</p>

<h2>9. КОНТАКТНАЯ ИНФОРМАЦИЯ И ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
<p>9.1. Запросы и обращения по вопросам обработки персональных данных, включая отзыв согласия, удаление, уточнение или получение информации о порядке обработки, направляются на электронный адрес: maxsimpai@yandex.ru</p>
<p>9.2. Настоящая Политика действует бессрочно до замены новой версией. При внесении изменений на странице https://mxmp.ru/privacy указывается дата последнего обновления. Новая версия вступает в силу с момента размещения (если иное не оговорено в тексте новой редакции).</p>
<p>9.3. Если какое-либо из положений настоящей Политики признаётся недействительным или незаконным, остальные положения продолжают своё действие в части, не противоречащей законодательству.</p>
<p>9.4. Споры и разногласия, связанные с обработкой персональных данных в рамках настоящей Политики, подлежат урегулированию путём переговоров. При невозможности достижения соглашения спор передаётся на рассмотрение суда по месту нахождения Оператора (если иное не предусмотрено законом).</p>


          </div>
          <div className={styles["fade-overlay"]}></div>
        </div>
      </main>

      <footer className={styles.footer}>
        <p>2025 © ООО «Максимпл»</p>
        <div className={styles.links}>
          <Link to="/terms" className={styles.link}>
            Условия и положения
          </Link>
          <Link to="/privacy" className={styles.link}>
            Политика конфиденциальности
          </Link>
        </div>
      </footer>
    </div>
  );
}

export default Privacy;
